<script>
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import WindowTitleBar from '../../../components/core/WindowTitleBar'

export default {
  name: 'MemberDue',
  components: {
    WindowTitleBar
  },
  data () {
    return {
      current_access_no: 0,
      statements: [],
      regno : '',
    }
  },
  store,
  created () {

  },
  mounted () {
    const self = this;

    try {

      $('#txtRegNo').focus();

    }catch (e) {
      alert(e);
    }

  },
  methods: {
    closeThis () {
      this.$emit('memberdue_window_closed');
      this.$router.push("/");
    },
    setTitle () {
      return 'Member Due';
    },
    loadData(){
      const self = this;


      if(self.regno.trim().length < 3 ){
        self.$refs.txtRegNo.select();
        $('#txtRegNo').focus();
        alert('Invalid Member Reg.No');
        return;
      }

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#memberdue_card').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.statements = [];
      // alert(`${process.env.VUE_APP_ROOT_API}api/lms/reports/outstanding/member/${self.$data.regno}`);
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/reports/outstanding/member/${self.$data.regno}`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {
            self.$data.statements = resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#memberdue_card').unblock();
      });
    },
    perform_clear_action(){
      const self = this;
      this.$data.statements = JSON.parse('{"id":0,"status":"Available","type":"Periodical","periodicity":"Monthly","isbn":"","code":"","name":"","title":"","qty":1,"language":"en","edition":"","edition_year":"","publish_year":0,"group_id":1,"rack":"","author_name":"","price":0,"pages":0,"notes":"","currency":"INR","conv_rate":1,"call_no":"","acquisition_date":"","bill_date":"","bill_no":"","authors":[],"publisher_id":1,"publisher":{"id":1,"status":"Active","name":""},"category_id":1,"category":{"id":1,"status":"Active","name":""},"department_id":1,"department":{"id":1,"status":"Active","name":""},"vendor_id":1,"vendor":{"id":1,"status":"Active","name":""},"library_id":1,"subject_id":1,"section_id":1,"subscribe_no":"","city":""}');
    },
  }
}
</script>

<template>
  <div class="card" id="memberdue_card">

    <WindowTitleBar title="Book Creation" @loadData="loadData" @close_window="closeThis">{{ setTitle() }}  </WindowTitleBar>

    <div class="card-body p-2">


      <div class="form-group row" >
          <label for="txtRegNo" class="col-md-1 col-form-label font-weight-semibold">Member RegNo</label>
          <div class="col-md-2 col-sm-4">
            <input type="text" class="form-control" id="txtRegNo" ref="txtRegNo" maxlength="20" autocomplete="off" v-model="regno" @keydown.enter="loadData">
          </div>

          <div class="col-md-3">
            <button type="button" class="btn btn-secondary btn-icon" @click="loadData"><i class="icon-search4"></i></button>
          </div>
        </div>

      <div class="form-group row p-1">
        <table class="table table-bordered table-condensed">
          <thead style="background-color: #76a797;">
            <th style="width: 50px;">S.No</th>
            <th style="width: 150px;">Accession No</th>
            <th>Title</th>
            <th style="width: 110px;">Issued On</th>
            <th style="width: 110px;">Due Date</th>
            <th style="width: 100px;">Due Day</th>
          </thead>
          <tbody  >
            <tr v-if="statements"  v-for="(statement,idx) in statements" :key="idx">
              <td>{{idx + 1}}</td>
              <td>{{statement.code}}</td>
              <td>{{statement.book_name}}</td>
              <td>{{statement.issue_date}}</td>
              <td>{{statement.due_date}}</td>
              <td>{{statement.due_days}}</td>
            </tr>
          </tbody>
        </table>
      </div>


    </div>

  </div>
</template>

<style scoped>
input:focus {
  background: #feff00;
}

textarea:focus {
  background: #feff00;
}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00!important;
  color: #0a0a0a!important;
}
</style>
